<template>
  <!--Start Shopping Cart Content-->

  <v-container fluid class="pa-0">
    <v-card color="transparent" elevation="0">
      <v-card-title class="px-2 pb-0 bg  v-app-bar--fixed">
        <div class="d-flex">
          <v-btn icon @click="backToShoppingCart()">
            <v-icon color="primary" size="26px"> mdi-arrow-left</v-icon>
          </v-btn>

          <span class="font-weight-medium text-h6 ml-4 primary--text">
            Bezahlung ({{ this.total | currency }})
          </span>
        </div>
      </v-card-title>
      <v-card-text class="pa-0">
        <div class="mx-6 mt-0 pt-0">
          <v-divider></v-divider>
        </div>

        <div class="text-end d-flex">
          <v-spacer></v-spacer>
          <div>
            <h6
              class="py-2 px-5 my-5 font-weight-bold tertiary lighten-3"
              style="border-top-left-radius: 50px; border-bottom-left-radius: 50px;"
            >
              Tisch Nr. {{ this.table }}
            </h6>
          </div>
        </div>

        <div class="mx-6">
          <h6>Hast du einen Gutscheincode?</h6>
          <div>
            <v-text-field
              placeholder="Gutscheincode"
              class="rounded-lg"
              readonly
              rounded
              filled
              :value="
                parseFloat(this.giftCard.balance) > 0
                  ? this.$options.filters.currency(this.giftCard.balance)
                  : 'Gutscheincode'
              "
              dense
              min-height="40px"
            >
              <v-btn
                v-if="!parseFloat(this.giftCard.balance) > 0"
                depressed
                to="/gift-card"
                slot="append"
                class="rounded-lg scan-btn"
                id="scan-btn"
                color="accent"
              >
                <v-icon>
                  mdi-qrcode-scan
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                depressed
                @click="removeGiftCard"
                slot="append"
                id="scan-btn-clear"
                class="rounded-lg scan-btn"
                color="error"
              >
                <v-icon>
                  mdi-qrcode-remove
                </v-icon>
              </v-btn>
            </v-text-field>
            <!--        <v-btn to="/gift-card" color="accent" depressed class="rounded-lg text-capitalize" dark>
              Hinzufugen
            </v-btn>-->
          </div>

          <h6>Wie mochtest du bezahlen?</h6>
          <!--<v-combobox
            v-model="select"
            :items="comboboxItems"
            class="rounded-lg"
            outlined
            dense
          ></v-combobox>-->
          <v-radio-group v-model="radios">
            <v-radio label="Paypal" value="Paypal" color="primary"></v-radio>
          </v-radio-group>

          <h5>Betrag</h5>

          <v-sheet color="#E9E9E9" class="px-3 py-6 rounded-xl">
            <div class="d-flex">
              <h5 class="grey--text">MwSt</h5>
              <v-spacer />
              <h6 class="text-right grey--text">
                In der Rechnung <br />
                enthalten
              </h6>
            </div>

            <div class="d-flex mt-3">
              <h6 class="font-weight-bold primary--text">Gesamt</h6>
              <v-spacer />
              <h6 class="font-weight-bold primary--text">
                {{ this.total | currency }}
              </h6>
            </div>
          </v-sheet>
          <v-btn
            :disabled="loading"
            :loading="loading"
            @click="pay"
            block
            x-large
            color="accent"
            depressed
            class="my-6 text-capitalize"
          >
            Zahlungspflichtig bestellen
          </v-btn>
        </div>

        <!--Start Succes-->
        <succes-dialog :dialog="this.dialog" />
        <!--End Succes-->
      </v-card-text>
    </v-card>
  </v-container>
  <!--End Shopping Cart Content-->
</template>

<script>
/*import components*/
import SuccesDialog from "@/components/localbee_table/Succes/SuccesDialog.vue";

/*import plugins*/
import { createNamespacedHelpers } from "vuex";
import ENDPOINTS from "@/plugins/axios/endpoints";

export default {
  components: { SuccesDialog },

  methods: {
    removeGiftCard() {
      this.$store.commit("Order/setGiftCard", null);
      this.$forceUpdate();
    },
    scan() {
      this.$router.push("/gift-card");
    },
    redirectToSucces() {
      this.dialog = true;
    },

    backToShoppingCart() {
      this.$router.push("/menu");
      //this.$router.go(-1);
    },

    /**
     * pay the order
     */
    pay() {
      this.loading = true;

      //prepare order request body
      let body = {
        table: {
          name: this.table,
          party: this.party
        },
        note: this.note,
        customer_id: null,
        openItems: this.card,
        items: this.ordered || [],
        giftCard: this.giftCard
      };

      //attach the old order id to the request to ensure that we'll update it
      if (this.order && this.order.id !== undefined)
        body.order_id = this.order.id;

      this.$axios
        .post(ENDPOINTS.SELF_SERVICE.ORDERS.CREATE, body)
        .then(res => {
          if (res.data.url) {
            let a = document.createElement("a");
            a.href = res.data.url;
            a.click();
            a.remove();
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters([
      "total",
      "note",
      "card",
      "ordered",
      "giftCard",
      "order"
    ]),
    ...createNamespacedHelpers("Table").mapGetters(["table", "party"])
  },
  data() {
    return {
      dialog: false,
      radios: "Paypal",
      select: ["Paypal"],
      comboboxItems: ["Barzahlung", "Online-Zahlung"],
      loading: false
    };
  }
};
</script>

<style>
#scan-btn,
#scan-btn-clear {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  min-height: 40px !important;
}
</style>
